import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import Seo from "../components/seo"
import PartneringGetform from "../components/forms/PartneringGetform"
export default function PartneringPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 660 }) {
        id
        content
        title
      }
    }
  `)
  const { content, positions, title } = data.wpPage

  return (
    <Layout>
      <Seo
        title="Partnering"
        description="Are you a pharmaceutical manufacturer looking for a reliable partner in the Nordics? We are now scaling up for future growth and are looking to further strengthen our portfolio in areas like biologic treatments, peptides and long acting injectables."
      />
      <div className="max-w-2xl mx-auto py-16 md:py-32 px-4">
        <div className="text-center">
          <h2 className="text-2xl md:text-5xl font-bold leading-tight pb-16">
            {title}
          </h2>
        </div>
        <div className="">
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="space-y-4"
          ></div>
          <div></div>
        </div>
        <PartneringGetform />
      </div>

      <Footer />
    </Layout>
  )
}

function Job({ jobitem }) {
  console.log(jobitem)
  return (
    <div className="flex flex-col space-y-4 my-4">
      <p>Location: {jobitem.location}</p>
      <p>
        New position:{" "}
        <a href={jobitem.fileUpload.localFile.publicURL}>{jobitem.title}</a>
      </p>
    </div>
  )
}
