import React from "react"

import axios from "axios"
import { Formik } from "formik"

import { navigate } from "gatsby"

const URL = "https://cms.newburypharma.com/wp-json"

const CF7_ID = "672"
// [yourName][organisation][email][telefon][eventDate][guests][additionalInfo]
// const formSchema = Yup.object().shape({
//   yourName: Yup.string().required("Required"),
//   email: Yup.string().email("Invalid email").required("Required"),
// })

function convertJsontoUrlencoded(obj) {
  let str = []
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
    }
  }
  return str.join("&")
}

export default function PartneringGetform() {
  const [state, setState] = React.useState(null || "")

  return (
    <div className="space-y-4">
      <h4 className="text-2xl font-heavy">Contact us:</h4>
      <Formik
        initialValues={{
          yourName: "",
          yourEmail: "",
          company: "",
          description: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          const submitData = async () => {
            try {
              const result = await axios({
                url: `${URL}/contact-form-7/v1/contact-forms/${CF7_ID}/feedback`,
                headers: {
                  "Content-Type":
                    "application/x-www-form-urlencoded; charset=utf-8",
                },
                method: "POST",
                data: convertJsontoUrlencoded(values),
              })

              setSubmitting(false)
              console.log(result.data.message)
              navigate("/thanks")
            } catch (error) {
              setState("Not sent. Please try again.")
              console.log(error)
            }
          }
          submitData()
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            className="form"
            action="https://getform.io/f/e4db2cd2-eb3c-4695-9006-cd6ff91dbae9"
            method="POST"
          >
            <div className="md:grid md:grid-cols-2 md:gap-4">
              <div>
                <label htmlFor="yourName">
                  Name:<span className="text-brandorange"></span>
                  <input
                    type="text"
                    name="yourName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.yourName}
                    required
                  />
                  {errors.yourName && touched.yourName ? (
                    <div>{errors.yourName}</div>
                  ) : null}
                </label>
              </div>
              <div>
                <label htmlFor="yourEmail">
                  Email:<span className="text-brandorange"></span>
                  <input
                    type="email"
                    id="yourEmail"
                    name="yourEmail"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.yourEmail}
                    required
                  />
                  {errors.yourEmail && touched.yourEmail ? (
                    <div>{errors.yourEmail}</div>
                  ) : null}
                </label>
              </div>

              <div>
                <label htmlFor="company">
                  Company
                  <input
                    type="text"
                    name="company"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company}
                  />
                  {errors.company && touched.company ? (
                    <div>{errors.company}</div>
                  ) : null}
                </label>
              </div>
            </div>

            <div>
              <label htmlFor="description" className="flex flex-col ">
                General description and inquire
                {/* <span className="text-brandorange">*</span> */}
                <textarea
                  rows="8"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  required
                />
                {errors.description && touched.description ? (
                  <div>{errors.description}</div>
                ) : null}
              </label>
            </div>
            {/* <p className="text-brandorange text-sm">*Obligatoriska fält</p> */}
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <span className="opacity-75">Sending</span>
              ) : (
                "Send"
              )}
            </button>
          </form>
        )}
      </Formik>
      {state ? <p>{state}</p> : null}
    </div>
  )
}
